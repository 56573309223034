import { useState } from 'react'
import { createPortal } from 'react-dom'
import { SupportChat } from '@dfds-ui/icons/system'
import { graphql } from 'gatsby'
import { theme } from '@dfds-ui/theme'

const scriptPromise = (src) =>
  new Promise((resolve) => {
    const e = document.createElement('script')
    e.src = src
    e.onload = resolve
    document.body.appendChild(e)
  })

const loadPuzzelScripts = async () => {
  const puzzelLatest =
    'https://chat.puzzel.com/Content/Client/js/jquery-intelecomchat.latest.min.js'
  if (!document.querySelector(`script[src="${puzzelLatest}"]`)) {
    // check if scripts have been previously loaded. Can happen if user navigates away and back
    await scriptPromise(
      'https://chat.puzzel.com/Content/Client/js/jquery-latest.min.js',
    )
    await scriptPromise(
      'https://chat.puzzel.com/Content/Client/js/jquery-intelecomchat.libs.latest.min.js',
    )
    await new Promise((resolve) => {
      const e = document.createElement('link')
      e.href = 'https://chat.puzzel.com/Content/Client/css/intelecom-light.css'
      e.rel = 'stylesheet'
      e.type = 'text/css'
      e.onload = resolve
      document.body.appendChild(e)
    })
    const style = document.createElement('style')
    style.innerHTML = `
    #chathost .intelecomchatheader { background-color: ${theme.colors.primary.main}; } 
    #chathost .intelecomchat__button { background: ${theme.colors.primary.main}; }
  `
    document.head.appendChild(style)
  }
  // this needs to always be loaded or it will not work
  await scriptPromise(puzzelLatest)
}

// we are loading the scripts manually to minimize footprint for other users
const loadPuzzel = async (
  setShow,
  { customerKey, configurationId, queueOverride },
) => {
  if (document.querySelector('#chathost').children.length > 0) return // chat have been loaded here. Nothing for us to do
  await loadPuzzelScripts()
  window.jQuery('#chathost').intelecomChat({
    customerKey,
    configurationId,
    chatlink: '#startchat',
    showStarter: false,
    showStarterWhenMinimized: false,
    onChatWindowShow: () => setShow(false),
    onChatWindowMinimize: () => setShow(true),
    onChatStart: (el, evt, params) => {
      const route = params.Variables.Route
      const queueKey = (queueOverride || [])
        .map((a) => a.match(/^([A-Z]+)=>([a-z_]+)$/))
        .filter((a) => a && a[1] === route)
        .map((a) => a[2])[0]
      if (queueKey)
        window.jQuery('#chathost').intelecomChat('reloadOptions', { queueKey })
    },
  })
  const poller = setInterval(() => {
    if (document.querySelector('#chathost').children.length > 0) {
      window.jQuery('#chathost').intelecomChat('startChat') // this unfortunately fails if run immediately which is why we poll
      clearInterval(poller)
    }
  }, 50)
}

const portalSafe = (component) => {
  return typeof document === 'undefined'
    ? component
    : createPortal(component, document.querySelector('#chatButtonPortal'))
}

const LiveChat = ({
  customerKey,
  chatEnabled,
  configurationId,
  queueOverride,
}) => {
  const [show, setShow] = useState(true)
  if (!chatEnabled) return null
  // we need to create a portal or z-index will be messed up with no way to fix it due to stacking context
  return portalSafe(
    <>
      <div
        role="button"
        onClick={() =>
          loadPuzzel(setShow, {
            customerKey,
            configurationId,
            queueOverride,
          })
        }
        onKeyDown={() =>
          loadPuzzel(setShow, {
            customerKey,
            configurationId,
            queueOverride,
          })
        }
        tabIndex={0}
        id="startchat"
        style={{
          position: 'fixed',
          bottom: '2em',
          right: '2em',
          display: show ? 'block' : 'none',
          zIndex: 100,
          backgroundColor: theme.colors.primary.main,
          borderRadius: '50%',
          padding: '10px',
          boxShadow: theme.elevation[4],
        }}
      >
        <SupportChat
          style={{
            width: '2.1em',
            height: '2.1em',
            cursor: 'pointer',
            color: theme.colors.surface.primary,
          }}
        />
      </div>
      <div id="chathost"></div>
    </>,
  )
}

export default LiveChat

export const query = graphql`
  fragment PuzzelLiveChat on contentful_PuzzelLiveChat {
    customerKey
    chatEnabled
    configurationId
    queueOverride
  }
`
